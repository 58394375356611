.treatment-popover {
    --backdrop-opacity: 0.2;
   div {
        width: 100%;
        word-wrap: break-word;
        background: var(--tile-background);
        border: 1px solid var(--ion-color-primary);
        border-radius: 4px;
        display: flex;
        padding: 15px 15px 10px 10px;
        font-family: var(--font-normal);
        font-weight: 400;
        font-size: var(--font-size-normal);
        justify-content: center;
   }
}

.treatment-entry-search-popover::part(content){
    max-height: 50%;
    width: auto;
    position: absolute;
    top: 25% !important;
    left: 20px;
    right: 20px;
    padding: 15px;
    overflow: hidden;
    height: 100%;
    background: var(--ion-toolbar-background);
}

.treatment-product {
     display: flex;
     padding-top: 7.5px;
     padding-bottom: 7.5px;
     align-items: center;
     gap: 20px;

     div {
          position: relative;
          width: 15%;
          text-align: start;
          font-size: var(--font-size-small);

          ion-input, ion-select {
               border-radius: 4px;
               border: 1px solid #C1C1C1;
          }

          ion-input {
               width: 50%;
               min-width: 50%;
          }
     }
}

[treat-header-all = true] {
     top: 128px;
}

[treat-header-all = false] {
     top: 0;
}

@media (min-width: 1000px) {
     .treatment-entry-search-popover::part(content) {
          left: 15vw !important;
          right: 15vw !important;
     }

 }

 @media (min-width: 1475px) {
     .treatment-entry-search-popover::part(content) {
          left: 25vw !important;
          right: 25vw !important;
     }
 }

 @media screen and (max-width: 575px) {
     .treatment-entry-search-popover::part(content) {
          left: 20px !important;
          right: 20px !important;
     }
 }
