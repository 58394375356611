@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// @import 'swiper/scss';
@import "swiper/css";
@import "swiper/css/grid";
@import "swiper/css/pagination";

// Screen size hidden
.invisible {
    visibility: hidden !important;
}

.hidden-xs-up {
    display: none !important;
}

@media (max-width: 575px) {
    .hidden-xs-down {
        display: none !important;
    }
}

@media (min-width: 576px) {
    .hidden-sm-up {
        display: none !important;
    }
}

@media (max-width: 767px) {
    .hidden-sm-down {
        display: none !important;
    }
}

@media (min-width: 768px) {
    .hidden-md-up {
        display: none !important;
    }
}

@media (max-width: 991px) {
    .hidden-md-down {
        display: none !important;
    }
}

@media (min-width: 992px) {
    .hidden-lg-up {
        display: none !important;
    }
}

@media (max-width: 1199px) {
    .hidden-lg-down {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    .hidden-xl-up {
        display: none !important;
    }
}

.hidden-xl-down {
    display: none !important;
}

ion-header {
    border-top: solid 4px var(--ion-color-primary);
    background-color: var(--ion-toolbar-background);
}

ion-toolbar {
    --min-height: 62px;
}

ion-title {
    font-size: 14px;
    font-family: var(--font-bold);
    text-align: start;

    a {
        color: var(--ion-text-color);
    }
}

ion-content {
    --padding-top: 30px;
    --padding-end: 20px;
    --padding-bottom: 30px;
    --padding-start: 20px;
    --overflow: overlay !important;
    font-size: 14px;
}

ion-searchbar {
    text-align: left;
    --background: var(--search-bar-background) !important;
    --box-shadow: 0 !important;
    --border-radius: 4px !important;
}

ion-button {
    font-family: var(--font-normal);
    text-transform: none;
    font-size: 14px;
}

ion-menu {
    --background: var(--tile-background) !important;
}

ion-button.secondary {
    --background: white;
    color: var(--ion-color-primary);
    border: solid 1px var(--ion-color-primary);
    border-radius: 3px;
    font-family: var(--font-medium);
    --box-shadow: none;
}

a {
    text-decoration: none;
    cursor: pointer;
}

a:hover {
    text-decoration: underline;
}

.alinea {
    margin: 30px 0 0 0;
}

.alert-checkbox-label.sc-ion-alert-md {
    text-overflow: unset !important;
    white-space: unset !important;
}

.alert-wrapper.sc-ion-alert-md {
    max-width: unset !important;
    min-width: 400px !important;
}

.alert-radio-group.sc-ion-alert-md,
.alert-checkbox-group.sc-ion-alert-md {
    max-height: 800px !important;
}

.alertCss .alert-button-group {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-around !important;
}

.alertNoButton,
.alertYesButton {
    display: block !important;
    border-radius: 0.3rem !important;
    padding-left: 4rem !important;
    padding-right: 4rem !important;
    margin-bottom: 1.5rem !important;
}

.alertNoButton {
    background-color: #d7d7d7 !important;
    color: #767676 !important;
}

.alertYesButton {
    background-color: #108c9d !important;
    color: white !important;
}

.dangerWarning {
    color: var(--ion-color-danger) !important;
}

.alertYesButtonDanger {
    background-color: var(--ion-toolbar-background) !important;
    border: 1px solid var(--ion-color-danger) !important;
    color: var(--ion-color-danger) !important;
}

.animal-sb {
    padding-inline-start: 0;
    padding-inline-end: 0;
    padding-top: 0;
    font-size: var(--font-size-normal);
    --icon-color: #cdcdcd;
    --placeholder-color: #cdcdcd;
}

.actionsheet-cancel-button {
    color: #999999 !important;
}

.dropdown .alert-checkbox-group .alert-checkbox-button-disabled,
.dropdown .alert-radio-group .alert-radio-button-disabled {
    .alert-checkbox-icon,
    .alert-radio-icon {
        display: none;
    }
    .alert-checkbox-label,
    .alert-radio-label {
        padding-inline-start: 15px;
        font-family: var(--font-bold);
        color: var(--ion-text-color-bold);
        background-color: #cccccc;
    }
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
    white-space: unset !important;
}

// .alert-ios .alert-wrapper{
//     max-width: unset !important;
//     width: 100% !important;
// }

// .alert-md .alert-wrapper{
//     max-width: unset !important;
//     width: 100% !important;
// }

h2 {
    font-family: var(--font-bold);
    font-size: 18px;
    margin: 0 0 10px 0;
}

h3 {
    font-family: var(--font-medium);
    font-size: 12px;
    margin: 0 0 15px 0;
}

h4 {
    font-family: var(--font-bold);
    font-size: 24;
    margin: 0 0 20px 0;
}

ul {
    padding-left: 20px;
}

.ion-select {
    background: var(--tile-background);
    border-radius: 3px;
    --padding-end: 10px;
    --padding-top: 0;
    --padding-bottom: 0;
    border: solid 1px var(--ion-border-color);
    margin: 0;
    box-sizing: border-box;
    height: 36px;
}

.link-logout {
    font-size: 12px;
    font-family: var(--font-medium);
    margin: 0 0 0 15px;
}

.menu-top {
    display: flex;
    align-items: center;
}

.menu-top a {
    color: var(--ion-text-color);
    height: 20px;
    margin-right: 30px;
}

.menu-top a:last-child {
    margin-right: 0;
}

.menu-top a:hover {
    text-decoration: none;
}

.menu-top a.selected {
    font-family: var(--font-bold);
    color: var(--ion-color-primary);
    border-bottom: solid 2px var(--ion-color-primary);
}

.tile-container {
    display: flex;
}

.tile {
    background: var(--tile-background);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 18px 18px 18px 18px;
    position: relative;
}

.tab-tile {
    border-top-left-radius: 0px;
}

.tile-tab {
    position: absolute;
    bottom: 100%;
    left: 0;
    box-shadow: -2px -2px 6px 0px rgb(0 0 0 / 2%);
}

.popup {
    text-align: center;
    .icon-warning {
        font-size: 50px;
    }
}

.progress-container {
    display: flex;
    font-size: 12px;
    color: var(--tile-chart-text-color);
}

.progress-container > div:first-child {
    margin: 0 20px 0 0;
}

.progress-container > div > div {
    height: 26px;
    display: flex;
    align-items: center;
}

.progress-container > div > div > div {
    font-size: 14px;
    font-family: var(--font-bold);
    color: var(--ion-text-color);
    margin: 0 0 0 8px;
    white-space: nowrap;
}

.progress-container ion-progress-bar {
    --background: transparent;
    height: 8px;
    border-radius: 4px;
}

.popup-menu {
    ion-item {
        --background: --segment-background;
    }
    ion-item:hover {
        --background: var(--ion-color-light);
        cursor: pointer;
    }
}

.tab-buttons {
    display: flex;
    font-size: 12px;
    align-items: center;
    div {
        background-color: var(--tile-background);
        padding: 8px 17px;
        border-right: solid 1px #b8b8b8;
        min-width: 130px;
        text-align: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        text-overflow: clip;
    }
    div:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    div:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-right: 0;
    }
    div.selected {
        background-color: var(--ion-color-primary);
        color: white;
    }
}

.tab-buttons.outlined {
    div {
        border-top: solid 1px #b8b8b8;
        border-bottom: solid 1px #b8b8b8;
    }
    div:first-child {
        border-left: solid 1px #b8b8b8;
    }
    div:last-child {
        border-right: solid 1px #b8b8b8;
    }
    div.disabled {
        pointer-events: none;
        background-color: #dddddd;
        color: #888888;
    }
}

.custom-select .alert-checkbox-group .alert-checkbox-button-disabled,
.custom-select .alert-radio-group .alert-radio-button-disabled {
    .alert-checkbox-icon,
    .alert-radio-icon {
        display: none;
    }

    .alert-checkbox-label,
    .alert-radio-label {
        padding-inline-start: 10px;
        font-family: var(--font-bold);
        color: var(--ion-text-color-bold);
        text-transform: capitalize;
    }
}

.custom-select .alert-wrapper {
    background: var(--tile-background);
}

.custom-select .select-interface-option .alert-checkbox-label,
.custom-select .select-interface-option .alert-radio-label {
    font-size: var(--font-size-normal);
    text-transform: capitalize;
}

.mte-custom-popover .popover-content {
    width: 400px;
}

.mte-exit-alert .alert-head {
    height: 120px;
    background-image: url("./assets/custom-ion-icons/warning.svg");
    background-position: center;
    background-repeat: no-repeat;
}

.mte-exit-alert .alert-message {
    text-align: center;
    line-height: 2.5;
    color: var(--ion-text-color-bold);
    font-family: var(--font-normal);
}

.mte-exit-alert .alert-button-group {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-around;

    button {
        width: 160px;
        height: 40px;
        border-radius: 4px;
        background: #d7d7d7;
        color: #767676;

        span {
            justify-content: center;
        }
    }

    button:last-child {
        background: #ca3d3d;
        color: white;
    }
}

// needed as swiper wrapper css wont apply if at component level
.swiper-wrapper {
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    // height: 100%;
    // width: 100%;
}

.segment {
    padding: 0;
    > ion-segment-button {
        --background-checked: var(--ion-color-primary);
        --color-checked: white;
        --border-width: 0;
        --indicator-color: none;
        --background: var(--segment-background);
        --color: 232736;
        font-weight: unset;
        min-height: unset;
        height: 36px;
        font-size: 12px;
        text-transform: unset;
        letter-spacing: unset;
        line-height: unset;
        border-right: solid 1px var(--ion-border-color);
        // min-width: 130px;
    }
    > ion-segment-button:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }
    > ion-segment-button:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-right: 0;
    }
}

@media (min-width: 1000px) {
    ion-toolbar {
        --padding-start: 15vw !important;
        --padding-end: 15vw !important;
    }

    ion-content {
        --padding-start: 15vw !important;
        --padding-end: 15vw !important;
    }

    .topmenu {
        padding-left: 15vw !important;
        padding-right: 15vw !important;
    }

    .icon-desktop-inner {
        padding-right: 0px;
    }

}

@media (max-width: 1000px) {
    .icon-desktop-inner {
        padding-right: 20px;
    }

    #appLogo {
        margin-left: 20px;
    }
}

@media (min-width: 1475px) {
    ion-toolbar {
        --padding-start: 25vw !important;
        --padding-end: 25vw !important;
    }

    ion-content {
        --padding-start: 25vw !important;
        --padding-end: 25vw !important;
    }

    .topmenu {
        padding-left: 25vw !important;
        padding-right: 25vw !important;
    }

    .icon-desktop-inner {
        padding-right: 0px;
    }
}

@media screen and (max-width: 575px) {
    ion-content {
        --padding-end: 20px;
        --padding-start: 20px;
    }

    .responsive-layout-large {
        display: none;
    }

    .responsive-layout-small {
        display: initial;
    }

    .hhc-value-col-inner {
        font-size: 23px !important;
    }
}

@media (min-width: 576px) {
    .responsive-layout-large {
        display: initial;
    }

    .responsive-layout-small {
        display: none;
    }
}

// @media (min-width: 800px) {
//     body {
//         background: #333333;
//     }
//     ion-router-outlet {
//         max-width: 800px;
//         margin: auto;
//     }
// }

.fl-search-i {
    --background: transparent;
    --min-height: 0px;
    --inner-padding-end: 0;
}

.fl-search {
    padding-inline-start: 0;
    padding-inline-end: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.fl-item-divider {
    --padding-start: 0;
    --padding-end: 0;
    min-height: 0;
    --inner-padding-top: 20px;
    border-bottom: solid 1px var(--ion-border-color);
    --color: inherit;
}

.fl-list-main-i {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: 100%;
    --inner-padding-end: 0;
    border-bottom: 1px solid var(--ion-color-primary);
}

.fl-list-main-i .fixed-col {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.125);
}

.fl-list-header {
    font-family: var(--font-bold);
}

.fl-list-ubn-i {
    min-width: 115px;
    max-width: 115px;
}

.fl-list-data-header {
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.experienceError {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 25%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    ion-icon {
        font-size: 125px;
        color: darkslategray;
    }

    p {
        color: darkslategray;
    }
}

.advice-warning {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-style: italic;
    align-items: center;
    justify-content: left;
    padding: 0.325rem 0 0.625rem 0.325rem;
}

.advice-warning-icon {
    display: flex;
    flex-direction: column;
    margin-right: 0.25rem;
}

.animal-item-warning {
    border-style: none;
    border-width: 1px;
    border-left-width: 0.5rem;
}

.warning-padding-adjust {
    margin-left: 0.5rem;
}

.warning-tile {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.hhc-value-col-inner {
    height: 68px;
    width: 100%;
    background: #ffffff;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #9c9c93;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hhc-value-none {
    box-shadow: none;
    background: transparent;
    border-radius: 0%;
}

.chip {
    margin: 0;
    border: solid 1px var(--ion-color-primary);
    background-color: var(--ion-color-primary);
    color: var(--ion-color-light);
}

.chip.deselected {
    background-color: transparent;
    color: var(--ion-color-primary);
}

ion-button.icon-only {
    width: 48px;
    height: 48px;
    --border-radius: 50%;
    margin: 0;
}

ion-menu {
    --width: 100%;
    --max-width: 400px;
    d4f-configuration-widget {
      height: 100%;
    }
}




